import Rellax from 'rellax';
import ScrollReveal from 'scrollreveal';

const rellax = new Rellax('.slide', {
  speed: 2,
  // wrapper: '.grid',
  center: false,
  round: true,
  vertical: true,
  horizontal: false,
  mobile: false
});

// // import locomotiveScroll from 'locomotive-scroll';

// // const scroll = new locomotiveScroll({
// //   el: document.querySelector('.scroll'),
// //   smooth: true
// // });

const settings = {
  easing: 'cubic-bezier(0.5, 0, 0, 1)',
  interval: 160,
  distance: '20px',
  offset: '-100px'
};

ScrollReveal().reveal('h2', settings);
// ScrollReveal().reveal('h3', settings);
ScrollReveal().reveal('p', settings);
