import './_menu';
import './_rellax';
import './_swup';
import './_splitting';
import './_video';

// const forms = [...document.querySelectorAll('form')]

// forms.forEach(
//   form => {
//     form.addEventListener('submit', (e) => {

//       const submit = form.querySelector('[type="submit"]')
//       submit.value = 'Verstuurd!'
//       submit.style.backgroundColor = '#2ecc71'
//       submit.style.borderColor = '#27ae60'
//       submit.style.borderColor = '#2ecc71'
//     })
//   })

console.clear()