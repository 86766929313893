// import 'splitting/dist/splitting.css';
// import 'splitting/dist/splitting-cells.css';
import Splitting from 'splitting';

const title = Splitting({
  target: '.title',
  by: 'words'
});

const split = Splitting({
  target: '.split',
  by: ['lines']
});

const section = Splitting({
  by: 'lines'
});